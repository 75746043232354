import React from "react";
import ReactDOM from "react-dom";

const loadApp = (App) => {
	document.addEventListener("DOMContentLoaded", () => {
		const node = document.getElementById("root");
		const data = JSON.parse(node.getAttribute("data"));

		ReactDOM.render(
			<App config={data} />,
			node
		)
	})
}
export default loadApp
