import React, { useEffect } from "react";
import cx from "classnames";
import { sample, get, isEmpty } from "lodash";

import { MAIN_PAGE_INSTANCE, USLUGI_PUBLIC_URL } from "../../../configs/envs";
import Cite from "./Cite";

import "./Welcome.scss";

export default function Welcome() {
	const bgImage = sample(MAIN_PAGE_INSTANCE.bgImages);

	const alignMapping = {
		left: "space-between",
		right: "space-between",
		get: function getter(align) {
			return this[align] || this.right;
		},
	};

	function handleClick(event) {
		event.preventDefault();

		const authPath = event.currentTarget.href;

		(authPath ? location.replace(authPath) : console.warn("Auth URL is not found"))();
	}

	useEffect(() => {
		document.body.classList.add("start");

		return () => document.body.classList.remove("start");
	}, []);

	return !isEmpty(bgImage) && (
		<div
			className={cx("welcome", { [`welcome_${bgImage.additionClass}`]: bgImage.additionClass })}
			style={{
				backgroundImage: `url(${USLUGI_PUBLIC_URL}/images/bg-images/${bgImage.img})`,
				justifyContent: alignMapping.get(bgImage.align),
			}}
		>
			<div className={cx("welcome__main", { "welcome__main-arm": get(MAIN_PAGE_INSTANCE, "isArm", false) })} style={Object.entries(bgImage).length ? { opacity: 1 } : {}}>
				<h1 className={"welcome__header"}>
					{get(MAIN_PAGE_INSTANCE, "title.left", "")} <i>{get(MAIN_PAGE_INSTANCE, "title.right", "")}</i>
				</h1>
				<div className={"welcome__body"}>
					<div className={"welcome__content"}>
						<Cite citates={MAIN_PAGE_INSTANCE.citates}/>
						<img
							src={`${USLUGI_PUBLIC_URL + MAIN_PAGE_INSTANCE.logo}`}
							alt={"Казань"}
							title={"Казань"}
						/>
					</div>
					<div className={"welcome__action"}>
						<a
							href={MAIN_PAGE_INSTANCE.login.url}
							className={"welcome__action-button"}
							onClick={handleClick}
						>
							<img
								className={cx("welcome__action-button_logo", { "welcome__action-button_logo-arm": get(MAIN_PAGE_INSTANCE, "isArm", false) })}
								src={`${USLUGI_PUBLIC_URL + MAIN_PAGE_INSTANCE.login.logo}`}
								alt={"Услуги Казань"}
								title={"Услуги Казань"}
							/>
							<span className={"welcome__action-button_text"}>
								{get(MAIN_PAGE_INSTANCE, "login.title.first_line", "")} <br /> {get(MAIN_PAGE_INSTANCE, "login.title.second_line", "")}
							</span>
						</a>
					</div>
				</div>
			</div>
			<div
				className={"welcome__copyright"}
			>
				<div>
					ИС Услуги включена в Единый реестр российских программ для электронных вычислительных машин и баз данных под реестровым номером <a
						href={"https://reestr.digital.gov.ru/reestr/305601/"}
						target={"_blank"}
						rel={"noreferrer"}
					>
						<span
							style={
								{
									color: "#fff",
									textDecoration: "underline",
								}
							}
						>
							4251
						</span>
					</a>
				</div>
			</div>
		</div>
	);
}
