import React from "react";

import BaseApp from "~/apps/BaseApp";

import Welcome from "./Welcome";
import Organizations from "./Organizations";

import "./Main.scss";

export default class Main extends BaseApp {
	templatesMap = {
		Welcome,
		Organizations,
	};

	constructor(props) {
		super(props);

		this.state = {
			template: "Welcome",
		};
	}

	componentDidMount() {
		document.body.classList.add("main");

		if (/session\/organization/.test(window.location.pathname)) {
			this.setState({
				template: "Organizations",
			});
		}
	}

	componentWillUnmount() {
		document.body.classList.remove("main");
	}

	render() {		
		return React.createElement(this.templatesMap[this.state.template]);
	}
}
