const appDomain = window?.location?.host || "test.uslugi.kzn.ru";

const bgImages_kazan = [
	{
		img: "kazan_01.jpg",
		additionClass: "bottom-right",
	},
	{
		img: "kazan_02.jpg",
		align: "left",
	},
	{
		img: "kazan_03.jpg",
		align: "right",
	},
	{
		img: "kazan_04.jpg",
		align: "right",
	},
	{
		img: "kazan_05.jpg",
		align: "left",
	},
	{
		img: "kazan_06.jpg",
		align: "left",
	},
	{
		img: "kazan_07.jpg",
		additionClass: "top-right",
	},
	{
		img: "kazan_08.jpg",
		align: "left",
	},
	{
		img: "kazan_09.jpg",
		additionClass: "top-right",
		align: "left",
	},
	{
		img: "kazan_10.jpg",
		additionClass: "bottom-right",
	},
	{
		img: "kazan_11.jpg",
		align: "left",
	},
	{
		img: "kazan_12.jpg",
		align: "right",
	},
];
const bgImages_tatarstan = [
	{
		img: "tatarstan_01.jpg",
		additionClass: "bottom-right",
	},
	{
		img: "tatarstan_02.jpeg",
		align: "left",
	},
	{
		img: "tatarstan_03.jpg",
		align: "right",
	},
	{
		img: "kazan_06.jpg",
		align: "left",
	},
	{
		img: "kazan_07.jpg",
		additionClass: "top-right",
	},
	{
		img: "kazan_08.jpg",
		align: "left",
	},
];
const citates_kazan = [
	{
		text: "– Важно не упускать момент. Как революцию когда-то объявлял известный наш товарищ, который не закончил Казанский университет, – вечером еще рано, утром уже поздно, есть только ночь.",
		author: "И. Р. Метшин",
	},
	{
		text: "В меру наших возможностей мы всегда готовы помогать",
		author: "И. Р. Метшин",
	},
	{
		text: "Без булдырабыз",
		author: "М. Ш. Шаймиев",
	},
	{
		text: "Самым важным считаю то, что вне зависимости от величины и удалённости того или иного населённого пункта, в нашем регионе создаются условия для комфортной жизни, учёбы, работы и отдыха",
		author: "И. Р. Метшин",
	},
	{
		text: "Действительно, если наше отношение к процессу образования отходов не изменится, все усилия будут нивелированы настоящими горами из мусора",
		author: "И. Р. Метшин",
	},
	{
		text: "В этом Казань должна стать пилотной территорией во всей стране. В данном городе живём мы, будут жить и следующие поколения",
		author: "М. Ш. Шаймиев",
	},
	{
		text: "Социальные проекты являются достоянием региона. Их реализация даёт возможность существенно улучшить жизнь населения в разных возрастных категориях",
		author: "М. Ш. Шаймиев",
	},
	{
		text: "Если рядом по соседству будут стоять предприниматели, которые как казаки-разбойники прибежали-убежали, то, конечно, всем нашим усилиям будет грош цена",
		author: "М. Ш. Шаймиев",
	},
];

const configs = {
	"test.uslugi.kzn.ru": {
		PUBLIC_API_URL: "https://api-test.uslugi.kzn.ru",
		USLUGI_PUBLIC_URL: "https://test.uslugi.kzn.ru",
		USLUGI_APP_SESSION_POINT: "https://test.uslugi.kzn.ru/api/session/organizations",
		API_PATH: process.env.USLUGI_APP_API_PATH,
		DADATA_SUGGESTIONS_URL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		MAP_URL: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
		GIST_LINK: "off",
		DADATA_TOKEN: "",
		MAIN_PAGE_INSTANCE: {
			bgImages: bgImages_kazan,
			title: {
				left: "УСЛУГИ",
				right: "TEST",
			},
			citates: citates_kazan,
			logo: "/images/welcome_logo/lion_logo.svg",
			login: {
				/**/
				logo: "/images/auth_logos/auth_logo_kazan.svg",
				title: {
					first_line: "Войти",
					second_line: "",
				},
				url: "https://test.uslugi.kzn.ru/auth/esia_oauth",
				/* /
				"logo": "/images/auth_logos/auth_logo_avanpost.svg",
				"title": {
					"first_line": "Войти через",
					"second_line": "госуслуги"
				},
				"url": "https://test.uslugi.kzn.ru/auth/ap"
        /**/
			},
			logout: {
				url: "https://test.uslugi.kzn.ru/auth/logout",
			},
		},
		ALLOW_DEBUG: true,
	},
	"demo-1.uslugi.kzn.ru": {
		PUBLIC_API_URL: "https://api-test.uslugi.kzn.ru",
		USLUGI_PUBLIC_URL: "https://test.uslugi.kzn.ru",
		USLUGI_APP_SESSION_POINT: "https://test.uslugi.kzn.ru/api/session/organizations",
		API_PATH: process.env.USLUGI_APP_API_PATH,
		DADATA_SUGGESTIONS_URL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		MAP_URL: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
		GIST_LINK: "off",
		DADATA_TOKEN: "",
		MAIN_PAGE_INSTANCE: {
			bgImages: bgImages_kazan,
			title: {
				left: "УСЛУГИ",
				right: "DEMO-1",
			},
			citates: citates_kazan,
			logo: "/images/welcome_logo/lion_logo.svg",
			login: {
				logo: "/images/auth_logos/auth_logo_kazan.svg",
				title: {
					first_line: "Войти через",
					second_line: "госуслуги",
				},
				url: "https://test.uslugi.kzn.ru/auth/esia_oauth",
			},
			logout: {
				url: "https://test.uslugi.kzn.ru/auth/logout",
			},
		},
		ALLOW_DEBUG: true,
	},
	"sandbox.uslugi.kzn.ru": {
		PUBLIC_API_URL: "https://api-test.uslugi.kzn.ru",
		USLUGI_PUBLIC_URL: "https://test.uslugi.kzn.ru",
		USLUGI_APP_SESSION_POINT: "https://test.uslugi.kzn.ru/api/session/organizations",
		API_PATH: process.env.USLUGI_APP_API_PATH,
		DADATA_SUGGESTIONS_URL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		MAP_URL: "https://basemap.geo4.me/worldmap/{z}/{x}/{y}.png",
		GIST_LINK: "off",
		DADATA_TOKEN: "",
		MAIN_PAGE_INSTANCE: {
			bgImages: bgImages_kazan,
			title: {
				left: "УСЛУГИ",
				right: "SANDBOX",
			},
			citates: citates_kazan,
			logo: "/images/welcome_logo/lion_logo.svg",
			login: {
				logo: "/images/auth_logos/auth_logo_kazan.svg",
				title: {
					first_line: "Войти через",
					second_line: "госуслуги",
				},
				url: "https://test.uslugi.kzn.ru/auth/esia_oauth",
			},
			logout: {
				url: "https://test.uslugi.kzn.ru/auth/logout",
			},
		},
		ALLOW_DEBUG: true,
	},
	"uslugi.kzn.ru": {
		PUBLIC_API_URL: "https://api.uslugi.kzn.ru",
		USLUGI_PUBLIC_URL: "https://uslugi.kzn.ru",
		USLUGI_APP_SESSION_POINT: "https://uslugi.kzn.ru/api/session/organizations",
		API_PATH: process.env.USLUGI_APP_API_PATH,
		DADATA_SUGGESTIONS_URL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		MAP_URL: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
		GIST_LINK: "on",
		DADATA_TOKEN: "",
		MAIN_PAGE_INSTANCE: {
			bgImages: bgImages_kazan,
			title: {
				left: "УСЛУГИ",
				right: "КАЗАНЬ",
			},
			citates: citates_kazan,
			logo: "/images/welcome_logo/lion_logo.svg",
			login: {
				logo: "/images/auth_logos/auth_logo_kazan.svg",
				title: {
					first_line: "Войти через",
					second_line: "госуслуги",
				},
				url: "https://uslugi.kzn.ru/auth/esia_oauth",
			},
			logout: {
				url: "https://uslugi.kzn.ru/auth/logout",
			},
		},
		ALLOW_DEBUG: false,
	},
	"arm.uslugi.kzn.ru": {
		PUBLIC_API_URL: "https://api.arm.uslugi.kzn.ru",
		USLUGI_PUBLIC_URL: "https://arm.uslugi.kzn.ru",
		USLUGI_APP_SESSION_POINT: "https://arm.uslugi.kzn.ru/api/session/organizations",
		API_PATH: process.env.USLUGI_APP_API_PATH,
		DADATA_SUGGESTIONS_URL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		MAP_URL: "https://basemap.geo4.me/worldmap/{z}/{x}/{y}.png",
		GIST_LINK: "on",
		DADATA_TOKEN: "",
		MAIN_PAGE_INSTANCE: {
			bgImages: bgImages_kazan,
			title: {
				left: "ARM-KZN",
				right: "ИСПОЛНИТЕЛЯ",
			},
			citates: citates_kazan,
			logo: "/images/welcome_logo/lion_logo.svg",
			login: {
				logo: "/images/auth_logos/auth_logo_avanpost.svg",
				title: {
					first_line: "Войти через",
					second_line: "госуслуги",
				},
				url: "https://arm.uslugi.kzn.ru/auth/ap",
			},
			logout: {
				url: "https://arm.uslugi.kzn.ru/auth/logout",
			},
		},
		ALLOW_DEBUG: false,
	},
	"arm.uslugi.tatarstan.ru": {
		PUBLIC_API_URL: "https://api.arm.uslugi.tatarstan.ru",
		USLUGI_PUBLIC_URL: "https://arm.uslugi.tatarstan.ru",
		USLUGI_APP_SESSION_POINT: "https://arm.uslugi.tatarstan.ru/api/session/organizations",
		API_PATH: process.env.USLUGI_APP_API_PATH,
		DADATA_SUGGESTIONS_URL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		en: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		MAP_URL: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
		GIST_LINK: "on",
		DADATA_TOKEN: "",
		MAIN_PAGE_INSTANCE: {
			bgImages: bgImages_tatarstan,
			title: {
				left: "АРМ",
				right: "ИСПОЛНИТЕЛЯ",
			},
			citates: citates_kazan,
			logo: "/images/welcome_logo/lion_logo.svg",
			login: {
				logo: "/images/auth_logos/auth_logo_avanpost.svg",
				title: {
					first_line: "Войти",
					second_line: "",
				},
				url: "https://arm.uslugi.tatarstan.ru/auth/ap",
			},
			logout: {
				url: "https://arm.uslugi.tatarstan.ru/auth/logout",
			},
			isArm: true,
		},
		ALLOW_DEBUG: false,
	},
	"localhost.uslugi.kzn.ru:3000": {
		PUBLIC_API_URL: "https://api-test.uslugi.kzn.ru",
		USLUGI_PUBLIC_URL: "https://test.uslugi.kzn.ru",
		USLUGI_APP_SESSION_POINT: "https://test.uslugi.kzn.ru/api/session/organizations",
		API_PATH: process.env.USLUGI_APP_API_PATH,
		DADATA_SUGGESTIONS_URL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		MAP_URL: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
		GIST_LINK: "off",
		DADATA_TOKEN: "",
		MAIN_PAGE_INSTANCE: {
			bgImages: bgImages_kazan,
			title: {
				left: "УСЛУГИ",
				right: "LOCAL",
			},
			citates: citates_kazan,
			logo: "/images/welcome_logo/lion_logo.svg",
			login: {
				logo: "/images/auth_logos/auth_logo_kazan.svg",
				title: {
					first_line: "Войти через",
					second_line: "test-esia",
				},
				url: "https://test.uslugi.kzn.ru/auth/esia_oauth",
			},
			logout: {
				url: "https://test.uslugi.kzn.ru/auth/logout",
			},
		},
		ALLOW_DEBUG: true,
	},
	"mfc.tatar.ru": {
		PUBLIC_API_URL: "https://api.uslugi.kzn.ru",
		USLUGI_PUBLIC_URL: "https://uslugi.kzn.ru",
		USLUGI_APP_SESSION_POINT: "https://uslugi.kzn.ru/api/session/organizations",
		API_PATH: process.env.USLUGI_APP_API_PATH,
		DADATA_SUGGESTIONS_URL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		MAP_URL: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
		GIST_LINK: "on",
		DADATA_TOKEN: "",
		MAIN_PAGE_INSTANCE: {
			bgImages: bgImages_kazan,
			title: {
				left: "УСЛУГИ",
				right: "КАЗАНЬ",
			},
			citates: citates_kazan,
			logo: "/images/welcome_logo/lion_logo.svg",
			login: {
				logo: "/images/auth_logos/auth_logo_kazan.svg",
				title: {
					first_line: "Войти через",
					second_line: "госуслуги",
				},
				url: "https://uslugi.kzn.ru/auth/esia_oauth",
			},
			logout: {
				url: "https://uslugi.kzn.ru/auth/logout",
			},
		},
		ALLOW_DEBUG: true,
	},
	get(type) {
		return this[type] || this["test.uslugi.kzn.ru"];
	},
};

/**/
const PUBLIC_API_URL = configs.get(appDomain).PUBLIC_API_URL;
const USLUGI_PUBLIC_URL = process.env.USLUGI_APP_PUBLIC_URL || configs.get(appDomain).USLUGI_PUBLIC_URL;
const USLUGI_APP_SESSION_POINT = configs.get(appDomain).USLUGI_APP_SESSION_POINT;
const API_PATH = configs.get(appDomain).API_PATH;
const DADATA_SUGGESTIONS_URL = process.env.USLUGI_APP_GIST_DADATA_SUGGESTIONS_URL || configs.get(appDomain).DADATA_SUGGESTIONS_URL;
const MAP_URL = process.env.USLUGI_APP_GIST_MAP_URL || configs.get(appDomain).MAP_URL;
const GIST_LINK = process.env.USLUGI_APP_GIST_LINK || "on";
const DADATA_TOKEN = process.env.USLUGI_APP_DADATA_TOKEN || configs.get(appDomain).DADATA_TOKEN;
const MAIN_PAGE_INSTANCE = configs.get(appDomain).MAIN_PAGE_INSTANCE;
const ALLOW_DEBUG = configs.get(appDomain).ALLOW_DEBUG || false;
const YM_ID = process.env.USLUGI_APP_YM;

/* /
const PUBLIC_API_URL = process.env.USLUGI_APP_PUBLIC_API_URL;
const USLUGI_PUBLIC_URL = "http://localhost:3000";
const USLUGI_APP_SESSION_POINT = `${PUBLIC_API_URL}/api/session/organizations`;
/**/

export {
	API_PATH,
	PUBLIC_API_URL,
	USLUGI_PUBLIC_URL,
	USLUGI_APP_SESSION_POINT,
	DADATA_SUGGESTIONS_URL,
	MAP_URL,
	GIST_LINK,
	DADATA_TOKEN,
	MAIN_PAGE_INSTANCE,
	ALLOW_DEBUG,
	YM_ID,
};
