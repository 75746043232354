import React from 'react'
import PropTypes from 'prop-types'


class BaseApp extends React.Component{
  static childContextTypes = {
    config: PropTypes.object
  }

  constructor(props){
    super(props)
    this.config = this.props.config
  }

  getChildContext() {
    return {
      config: this.config
    }
  }

}
export default BaseApp
