import React, { useEffect, useState } from "react";
import get from "lodash/get";

import organizationLogo from "../img/organization-logo.svg";
import personLogoMale from "../img/person-logo-male.svg";
import personLogoFemale from "../img/person-logo-female.svg";
import headmadeLogo from "../img/headmade-logo.svg";
import mainLogo from "../img/uslugi-logo.svg";

import { USLUGI_APP_SESSION_POINT } from "../../../configs/envs";

import "./Organizations.scss";

// TODO: Need to use loader

export default function Organizations() {
	const [organizations, setOrganizations] = useState([]);

	function handleSubmit(event) {
		event.preventDefault();

		const form = event.target;
		const formData = new FormData(form);
		const method = form.method;
		const action = form.action;

		fetch(action, {
			method,
			credentials: "include",
			body: formData,
		})
			.then(response => {
				const { ok, status } = response;

				if (ok) {
					if (+status === 200) {
						return response.json();
					}

					console.warn("Status different from 200");

					location.replace("/");
				} else {
					console.warn("Error response");

					location.replace("/");
				}
			})
			.then(data => {
				const lctn = get(data, "location", "");

				if (lctn) {
					location.replace(lctn);
				}
			})
			.catch(error => console.error(error));
	}

	useEffect(() => {
		fetch(USLUGI_APP_SESSION_POINT, {
			credentials: "include",
		})
			.then(
				response => {
					const { ok, status } = response;

					if (ok) {
						// TODO: need to check more statuses

						if (+status === 200) {
							return response.json();
						}

						console.warn("Status different from 200");

						location.replace("/");
					} else {
						console.warn("Error response");

						location.replace("/");
					}
				},
				error => {
					console.error("Response resolve error ", { error });
				}
			)
			.then(
				data => {
					if (data && data.length) {
						setOrganizations(data);
					} else {
						console.warn("Empty organizations data");

						location.replace("/");
					}
				},
				error => {
					console.error("Data resolve error ", { error });
				}
			)
			.catch(error => {
				console.error("Organizations request error: ", { error });
			});
	}, []);

	const avatarHash = {
		M: personLogoMale, 
		F: personLogoFemale,
		get(type) {
			return this[type] || this.M
		}
	}

	return (
		<div className={"organizations"}>
			<header className={"organizations-header"}>
				<div className={"container"}>
					<h1>
                        ИС
						<span>Услуги</span>
					</h1>
				</div>
			</header>
			<main className={"organizations__main"}>
				<div className={"container"}>
					<h2>Войти как</h2>
					<div style={{ display: "flex",
						justifyContent: "center" }}>
						<ul className={"organizations__list"}>
							{organizations.map((organization, idx) => {
								const title = get(organization, "title", "");
								const oid = get(organization, "oid", "");
								const ogrn = get(organization, "ogrn", "");
								const gender = get(organization, "gender", "");
								const avatarSrc = get(organization, "avatar", avatarHash.get(gender))

								return (
									<li
										key={`organization-${idx}`}
										className={"organizations__item"}
									>
										<form
											action={USLUGI_APP_SESSION_POINT}
											method={"POST"}
											onSubmit={handleSubmit}
										>
											<input
												type={"hidden"}
												name={"oid"}
												value={oid || ""}
											/>
											<button>
												<span className={"img"}>
													{ogrn ? (
														<img
															src={
																organizationLogo
															}
															title={
																"Организация"
															}
															alt={
																"Госуслуги организация"
															}
														/>
													) : (
														<img
															src={avatarSrc}
															title={
																"Физическое лицо"
															}
															alt={
																"Госуслуги физлицо"
															}
														/>
													)}
												</span>
												<span className={"titles"}>
													<span>{title}</span>
													{ogrn && (
														<span>
                                                            ОГРН: {ogrn}
														</span>
													)}
												</span>
												<span className={"arrow"} />
											</button>
										</form>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</main>
			<footer className={"organizations-footer"}>
				<div className={"container"}>
					<div className={"organizations-footer__item"}>
						<div className={"organizations-footer__icon"}>
							<img
								src={mainLogo}
								alt={"Госуслуги"}
								title={"Гусуслуги"}
							/>
						</div>
						<div className={"organizations-footer__text"}>
							<p></p>
						</div>
					</div>
					<div
						className={
							"organizations-footer__item organizations-footer__item_wide"
						}
					>
						<div className={"organizations-footer__icon"}>
							<img
								src={headmadeLogo}
								alt={"HeadMade"}
								title={"HeadMade"}
							/>
						</div>
						<div className={"organizations-footer__text"}>
							<span>{`2015-${new Date().getFullYear()}`}</span>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
}
