import React, {useEffect, useState} from 'react';
import sample from "lodash/sample";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import "./Cite.scss";

export default function Cite({citates}) {
	const [currentCit, setCurrenCit] = useState({})

	useEffect(() => {
		setCurrenCit(sample(citates));
	}, [])

	return !isEmpty(currentCit) && (
		<div className={"cite"}>
			<p className={"cite__text"}>
				{currentCit.text}
			</p>
			<p className={"cite__author"}>
				{currentCit.author}
			</p>
		</div>
	)
}

Cite.propTypes = {
	citates: PropTypes.array
}

Cite.defaultProps = {
	citates: []
}

Cite.displayName = "WelcomePageCite"